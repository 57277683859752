const managedDomainKey = 'managedDomain'

export const getManagedDomain = (): string | null =>
  (typeof window !== 'undefined')
    ? window.localStorage.getItem(managedDomainKey)
    : null

export const setManagedDomain = (domainName: string): void =>
  window.localStorage.setItem(managedDomainKey, domainName)

export const clearManagedDomain = () =>
  window.localStorage.removeItem(managedDomainKey)

export const isManagedDomain = (domain: string) => getManagedDomain() === domain

export const hasFunds = (): boolean => (
  getManagedDomain() === 'Netastract'
)

export const hasFiat = (): boolean => (
  getManagedDomain() === 'Netastract' || getManagedDomain() === 'Fugiro'
)

export const hasCrypto = (): boolean => (
  getManagedDomain() !== 'Fugiro'
)
